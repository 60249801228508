import React, {useEffect} from 'react'
import PageLayout from '../../layouts/PageLayout'
import Button from "../../components/Button";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

export default function Home() {

    const colors = [
        "#ffcef8",
        "#b2f9b9",
        "#ccccff",
        "#bbedff",
        "#f0fe9d",
        "#ffb585",
    ];
    const pills = [
        {
            text: "Diffusez",
            rotation: "0deg",
            x: "35%",
            y: '30%',
            fs: 44
        }, {
            text: "Communiquez",
            rotation: "0deg",
            x: "50%",
            y: '20%',
            fs: 36
        }, {
            text: "Captivez",
            rotation: "0deg",
            x: "60%",
            y: '30%',
            fs: 30
        }, {
            text: "Partagez",
            rotation: "-6deg",
            x: "47.5%",
            y: '35%',
            fs: 24
        }, {
            text: "Propulsez",
            rotation: "-10deg",
            x: "42.5%",
            y: '45%',
            fs: 41
        }, {
            text: "Intéressez",
            rotation: "15deg",
            x: "55%",
            y: '42.5%',
            fs: 33
        }, {
            text: "Allumez",
            rotation: "5deg",
            x: "57%",
            y: '50%',
            fs: 24
        }, {
            text: "Publiez",
            rotation: "-15deg",
            x: "62.5%",
            y: '37.5%',
            fs: 23
        }, {
            text: "😄",
            rotation: "0deg",
            x: "63%",
            y: '48%',
            fs: 36
        }, {
            text: "🍿",
            rotation: "-32deg",
            x: "41%",
            y: '37%',
            fs: 50
        }, {
            text: "👍",
            rotation: "-32deg",
            x: "71%",
            y: '22%',
            fs: 50
        },
    ];
    const partners = [
        {
            imgName: "lush.png",
            label: "logo Lush"
        }, {
            imgName: "pour-vous.png",
            label: "logo Pour Vous"
        }, {
            imgName: "creme-coiffeur.png",
            label: "logo Crème Coiffeur"
        }, {
            imgName: "rituals.png",
            label: "logo Rituals"
        }, {
            imgName: "la-mie-caline.png",
            label: "logo La Mie Caline"
        }, {
            imgName: "paula.png",
            label: "logo Paula Shop"
        },
    ];

    const connectors = [
        {
            text: "Post Instagram",
            icon: "instagram.png"
        }, {
            text: "Google My Business",
            icon: "googlemybusiness.png"
        }, {
            text: "Galerie personnelle",
            icon: "galerie.png"
        }, {
            text: "Produits Prestashop",
            icon: "prestashop.png"
        }, {
            text: "Produits Shopify",
            icon: "shopify.png"
        }, {
            text: "Avis vérifies",
            icon: "avis-verifie.png"
        }, {
            text: "WordPress",
            icon: "wordpress.png"
        },
    ];

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        gsap.to(".pill", {
            left: "50%",
            top: "50%",
            scrollTrigger: {
                trigger: "#home section.hero",
                pin: true,
                triggerHook: 0,
                start: "top top", // when the top of the trigger hits the top of the viewport
                end: "+=100%", // end after scrolling 500px beyond the start
                scrub: 2, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
            },
        });

        gsap.to("section.exploration", {
            background: "#000",
            scrollTrigger: {
                trigger: "#home section.exploration",
                triggerHook: 0,
                start: "top center", // when the top of the trigger hits the top of the viewport
                end: "+=10%", // end after scrolling 500px beyond the start
                scrub: 2, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
            },
        });

        // gsap.to("section.exploration", {
        //     scrollTrigger: {
        //         trigger: "#home section.exploration",
        //         triggerHook: 0,
        //         start: "top top", // when the top of the trigger hits the top of the viewport
        //         end: "+=100%", // end after scrolling 500px beyond the start
        //         scrub: 2, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
        //         toggleClass: {targets: "header", className: "theme-white"}
        //     },
        // });

        gsap.to(".overlay-slider-connector", {
            boxShadow: "inset 0 0 60px 235px #000",
            scrollTrigger: {
                trigger: "#home section.exploration",
                triggerHook: 0,
                start: "top center", // when the top of the trigger hits the top of the viewport
                end: "+=10%", // end after scrolling 500px beyond the start
                scrub: 2, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
            },
        });
    }, []);


    return (
        <PageLayout name={"home"}>
            <section className={"hero"}>
                <div className="sofa">
                    {pills.map(pill => {
                        return <div className={pill.text.length === 2 ? "pill emote" : "pill"} style={{
                            fontSize: pill.fs,
                            backgroundColor: colors[Math.round(Math.random() * 5)],
                            top: pill.y,
                            left: pill.x,
                            rotate: pill.rotation,
                            animationDelay: Math.random() * 0.2 + 's'
                        }}>{pill.text}</div>
                    })}
                    <div className={"canap-illu"}>
                        <img src={"/images/canap.svg"} alt="illustration canapé"/>
                    </div>
                </div>
                <div className="content">
                    <h1 className={"headline"}>Diffusez du contenu dans votre commerce, transformez le temps d'attente
                        de vos clients.</h1>
                    <Button>Let's go !</Button>
                </div>
            </section>
            <section className="exploration">
                <div className="container">
                    <div className="top-content">
                        <p className="little-headline">Plus de 10k boutiques utilisent déjà Canapp.</p>
                        <div className="logos-partners">
                            {partners.map(partner => {
                                return <img src={"/images/partners/" + partner.imgName} alt={partner.label} />
                            })}
                        </div>
                    </div>
                    <div className="bottom-content">
                        <h2 className="big-headline">Créateurs <span className="green-gradient">d'expériences</span> <br/><span className="blue-gradient">numériques</span></h2>
                        <p className="little-headline w-600">Propulsez votre activité commerciale, en partageant</p>
                        <div className="connector-slider">
                            <div className="overlay-slider-connector"></div>
                            <Swiper
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            modules={[Autoplay]}
                            spaceBetween={20}
                            slidesPerView={'auto'}
                            centeredSlides={true}
                            loop={true}
                            >
                                {connectors.map(connector => {
                                return <SwiperSlide className="item-slider"><img src={"/images/connectors/" + connector.icon} alt='' />{connector.text}</SwiperSlide>
                            })}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
            <section className="canapp">
                <div className="container">
                    <h2>Canapp quésako ?</h2>
                    <p className="subtitle">Où on va, qu’est-ce qu’on fait ?</p>
                    <div className="img-content">
                        <div className="img">
                            <img src="/images/etape-1.svg" alt="etape 1" />
                        </div>
                        <div className="content">
                            <h3>Connectez <br/> vos réseaux</h3>
                            <h4>Et rassemblez tout votre contenu</h4>
                            <Button href="#">En savoir plus</Button>
                        </div>
                    </div>
                    <div className="img-content">
                        <div className="content">
                            <h3>Créez votre slider</h3>
                            <h4>Personnalisez le sans limites</h4>
                            <Button href="#">En savoir plus</Button>
                        </div>
                        <div className="img">
                            <img src="/images/etape-2.svg" alt="etape 2" />
                        </div>
                    </div>
                    <div className="img-content">
                        <div className="img">
                            <img src="/images/etape-3.svg" alt="etape 3" />
                        </div>
                        <div className="content">
                            <h3>Diffusez le dans <br/> votre commerce</h3>
                            <h4>Transformez le temps d’attente de vos clients.</h4>
                            <Button href="#">En savoir plus</Button>
                        </div>
                    </div>
                </div>
            </section>
        </PageLayout>
    )
}
