import React from "react";
import ReactDOM from "react-dom/client";
import './styles/index.scss';
import Home from "./routes/home";
import reportWebVitals from "./reportWebVitals";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import 'swiper/css';
import 'swiper/css/autoplay';

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
        children: [],
    },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <RouterProvider router={router}/>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
