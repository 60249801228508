import React from 'react'
import Header from '../components/Header.jsx'

export default function PageLayout({children, name}) {
    return (
        <>
            <Header></Header>
            <div id={name.toLowerCase()} className={"page-wrapper"}>
                {children}
            </div>
        </>
    )
}
