import React from 'react'
import {NavLink} from 'react-router-dom'
import Button from './Button'


export default function Header() {
    const links = [
        {
            text: "Solutions",
            path: "/solutions"
        },
        {
            text: "Abonnements",
            path: "/abonnements"
        },
        {
            text: "Entreprise",
            path: "/entreprise"
        },
        {
            text: "Communauté",
            path: "/communaute"
        },
        {
            text: "Ressources",
            path: "/ressources"
        }
    ]

    return (
        <header>
            <img src={"images/logo/Canapp-Logo.webp"}
                 srcSet={"images/logo/Canapp-Logo@2x.webp 2x,images/logo/Canapp-Logo@3x.webp 3x"}
                 className="header-logo" alt="logo" width={126} height={23}/>
            <div className="right">
                <nav>
                    {links.map((link) => {
                        return <NavLink to={link.path} key={"link" + link.path}>{link.text}</NavLink>
                    })}
                    <Button>Essayer gratuitement</Button>
                    <NavLink to={"/account"}><img src={"images/icons/avatar.svg"} alt="avatar icon" className={'account'}/></NavLink>
                </nav>
            </div>
        </header>
    )
}
