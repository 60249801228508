import React, {useRef} from 'react'
import {NavLink} from "react-router-dom";

export default function Button({children, href}) {
    const backgroundDot = useRef();

    function handleHover(e) {
        let rect = e.target.getBoundingClientRect();
        let x = e.clientX - rect.left; //x position within the element.
        let y = e.clientY - rect.top;  //y position within the element.
        backgroundDot.current.style.left = x + 'px';
        backgroundDot.current.style.top = y + 'px';
    }

    return (
        <>
            {!href && <button className='button' onMouseEnter={(e) => handleHover(e)}>
                <span className={"btn-content"}>
                    {children}
                </span>
                <span ref={backgroundDot} className="background-effect"></span>
            </button>}

            {href && <NavLink className='button' onMouseEnter={(e) => handleHover(e)} to={href}>
                <span className={"btn-content"}>
                    {children}
                </span>
                <span ref={backgroundDot} className="background-effect"></span>
            </NavLink>}
        </>
    )
}
